// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import React from "react";

import View from "layouts/sections/components/View";

// PageHeaders page components
import HeaderOne from "layouts/sections/elements/badges/components/BadgesSimple";

// PageHeaders page components code

// import View from "layouts/sections/components/View";

// PageHeaders page components
// import HeaderOne from "layouts/sections/navigation/navbars/components/NavbarDark";

// PageHeaders page components code

function PageHeaders() {
  return (
    <BaseLayout
      title="Rules"
      breadcrumb={[
        { label: "Home", route: "/presentation" },
        { label: "Events", route: "/sections/page-sections/features" },
        { label: "TECH SPLASH" },
      ]}
    >
      <View title="Event Schedule - Day 1(10:30am-1:00pm) Day 2(10:30am to 12:00pm)" height="50rem">
        <HeaderOne />
      </View>
    </BaseLayout>
  );
}

export default PageHeaders;
