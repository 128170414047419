// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/co-woking.gif";

function HeaderOne() {
  return (
    <MKBox component="header" position="relative" height="100%">
      <MKBox component="nav" position="absolute" top="0.5rem" width="100%">
        <Container>
          <Grid container flexDirection="column" alignItems="left">
            <Stack direction="row" spacing={2}>
              <MKTypography
                component={Link}
                variant="button"
                color="white"
                fontWeight="regular"
                py={0.8125}
                mr={2}
                sx={{
                  "&:hover": {
                    color: "lightgreen", // Highlighted color on hover
                  },
                  "@media (max-width: 600px)": {
                    fontSize: "50%", // Adjust the font size for mobile responsiveness
                  },
                }}
              >
                COORDINATORS:
              </MKTypography>
              <MKTypography
                component={Link}
                href={`https://wa.me/7483304123`}
                target="_blank"
                rel="noreferrer"
                variant="button"
                color="white"
                fontWeight="regular"
                py={0.8125}
                mr={2}
                sx={{
                  "&:hover": {
                    color: "lightgreen", // Highlighted color on hover
                  },
                  "@media (max-width: 600px)": {
                    fontSize: "50%", // Adjust the font size for mobile responsiveness
                  },
                }}
              >
                {" "}
                Sanath K G
                <WhatsAppIcon sx={{ ml: 1 }} />
              </MKTypography>
              <MKTypography
                component={Link}
                href={`https://wa.me/+918884070011`}
                target="_blank"
                rel="noreferrer"
                variant="button"
                color="white"
                fontWeight="regular"
                py={0.8125}
                mr={2}
                sx={{
                  "&:hover": {
                    color: "lightgreen", // Highlighted color on hover
                  },
                  "@media (max-width: 600px)": {
                    fontSize: "50%", // Adjust the font size for mobile responsiveness
                  },
                }}
              >
                {" "}
                Ishwar
                <WhatsAppIcon sx={{ ml: 1 }} />
              </MKTypography>
              <MKTypography
                component={Link}
                href={`https://wa.me/+919573327685`}
                target="_blank"
                rel="noreferrer"
                variant="button"
                color="white"
                fontWeight="regular"
                py={0.8125}
                mr={2}
                sx={{
                  "&:hover": {
                    color: "lightgreen", // Highlighted color on hover
                  },
                  "@media (max-width: 600px)": {
                    fontSize: "50%", // Adjust the font size for mobile responsiveness
                  },
                }}
              >
                {" "}
                Sruthi S
                <WhatsAppIcon sx={{ ml: 1 }} />
              </MKTypography>
              {/* Add similar styling for other coordinators if needed */}
            </Stack>
          </Grid>
        </Container>
      </MKBox>
      <MKBox
        display="flex"
        alignItems="center"
        minHeight="100%"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} md={7} lg={6} flexDirection="column" justifyContent="center">
            <MKTypography
              variant="h1"
              color="white"
              mb={3}
              sx={({ breakpoints, typography: { size } }) => ({
                fontSize: size["6xl"],
                [breakpoints.down("md")]: {
                  fontSize: size["2xl"],
                },
              })}
            >
              CRYPTIC ENCLAVE
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.9}
              pr={9}
              mr={6}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "clamp(0.1rem, 3vw, 1.2rem)", // Default font size range
                "@media (min-width: 300px) and (max-width: 450px)": {
                  fontSize: "2.9vw", // Font size for widths between 768px and 800px
                },
                "@media (min-width: 451px) and (max-width: 562px)": {
                  fontSize: "3vw", // Font size for 700px and below
                },
                "@media (min-width: 575px) and (max-width: 618px)": {
                  fontSize: "2.5vw", // Font size for widths between 768px and 800px
                },
                "@media (min-width: 619px) and (max-width: 767px)": {
                  fontSize: "2.2vw", // Font size for widths between 768px and 800px
                },
              }}
            >
              🔥 The team size should be exactly 3
              <br />
              🔥 Number of rounds: 3
              <br />
              🔥 Per Team Registration Fee: Rs 250
              <br />
              🔥 Team should be present at the given time
              <br />
              🔥 Follow provided instructions closely
              <br />
              🔥 No photography inside the escape room
              <br />
            </MKTypography>

            <Stack direction="column" spacing={1} mt={3}>
              <a href="https://forms.gle/DDUHjLMppsQSWn1H6">
                <MKButton color="white">REGISTER NOW</MKButton>
              </a>
            </Stack>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default HeaderOne;
