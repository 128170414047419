/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import coding from "assets/images/coding.gif";
import web from "assets/images/web.gif";
import game from "assets/images/game.gif";
import quiz from "assets/images/quiz.gif";
import talk from "assets/images/talk.gif";
import visual from "assets/images/visual.gif";
import manager from "assets/images/manager.gif";
import hunt from "assets/images/hunt.gif";
import photo from "assets/images/photo.gif";

export default [
  {
    title: "",
    description: "",
    items: [
      {
        image: coding,
        name: "BINARY BRAINS",
        count: "Code Masters",
        route: "/sections/navigation/navbars",
        pro: true,
      },
      {
        image: web,
        name: "WEB SCULPT",
        count: "Web Designing",
        route: "/sections/input-areas/forms",
        pro: true,
      },
      {
        image: manager,
        name: "MAVERIX",
        count: "IT Manager",
        route: "/sections/navigation/pagination",
        pro: true,
      },
      {
        image: game,
        name: "GAMING",
        count: "BGMI / VALORANT",
        route: "/sections/elements/avatars",
        // pro: true,
      },
      {
        image: quiz,
        name: "MIND MATTERS",
        count: "Tech Quizz",
        route: "/sections/elements/badges",
        pro: true,
        // pro: true,
      },
      {
        image: talk,
        name: "TECH SPLASH",
        count: "Tech Talk",
        route: "/sections/elements/breadcrumbs",
        pro: true,
        // pro: true,
      },
      {
        image: visual,
        name: "BYTE VISTA",
        count: "Data Visualization",
        route: "/sections/navigation/nav-tabs",
        pro: true,
      },
      {
        image: hunt,
        name: "CRYPTIC ENCLAVE",
        count: "Escape Room",
        route: "/sections/elements/buttons",
        pro: true,
        // pro: true,
      },
      {
        image: photo,
        name: "PIXEL PERFECT",
        count: "Photography",
        route: "/sections/elements/dropdowns",
        pro: true,
        // pro: true,
      },
      // {
      //   image: `${imagesPrefix}/projects.jpg`,
      //   name: "Applications",
      //   count: 6,
      //   pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/logo-area.jpg`,
      //   name: "Logo Areas",
      //   count: 4,
      //   pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/footers.jpg`,
      //   name: "Footers",
      //   count: 10,
      //   pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/general-cards.jpg`,
      //   name: "General Cards",
      //   count: 9,
      //   pro: true,
      // },
      // {
      //   image: `${imagesPrefix}/content-sections.jpg`,
      //   name: "Content Sections",
      //   count: 8,
      //   pro: true,
      // },
    ],
  },
  // {
  //   title: "Navigation",
  //   description: "30+ components that will help go through the pages",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/navbars.jpg`,
  //       name: "Navbars",
  //       count: 4,
  //       route: "/sections/navigation/navbars",
  //     },
  //     {
  //       image: `${imagesPrefix}/nav-tabs.jpg`,
  //       name: "Nav Tabs",
  //       count: 2,
  //       route: "/sections/navigation/nav-tabs",
  //     },
  //     {
  //       image: `${imagesPrefix}/pagination.jpg`,
  //       name: "Pagination",
  //       count: 3,
  //       route: "/sections/navigation/pagination",
  //     },
  //   ],
  // },
  // // {
  // //   title: "Input Areas",
  // //   description: "50+ elements that you need for text manipulation and insertion",
  // //   items: [
  // //     {
  // //       image: `${imagesPrefix}/newsletters.jpg`,
  //       name: "Newsletters",
  //       count: 6,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/contact-sections.jpg`,
  //       name: "Contact Sections",
  //       count: 8,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/forms.jpg`,
  //       name: "Forms",
  //       count: 3,
  //       route: "/sections/input-areas/forms",
  //     },
  //     {
  //       image: `${imagesPrefix}/inputs.jpg`,
  //       name: "Inputs",
  //       count: 6,
  //       route: "/sections/input-areas/inputs",
  //     },
  //   ],
  // },
  // {
  //   title: "Attention Catchers",
  //   description: "20+ Fully coded components that popup from different places of the screen",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/alerts.jpg`,
  //       name: "Alerts",
  //       count: 4,
  //       route: "/sections/attention-catchers/alerts",
  //     },
  //     {
  //       image: `${imagesPrefix}/toasts.jpg`,
  //       name: "Notifications",
  //       count: 3,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/popovers.jpg`,
  //       name: "Tooltips & Popovers",
  //       count: 2,
  //       route: "/sections/attention-catchers/tooltips-popovers",
  //     },
  //     {
  //       image: `${imagesPrefix}/modals.jpg`,
  //       name: "Modals",
  //       count: 5,
  //       route: "/sections/attention-catchers/modals",
  //     },
  //   ],
  // },
  // {
  //   title: "Elements",
  //   description: "80+ carefully crafted small elements that come with multiple colors and shapes",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/buttons.jpg`,
  //       name: "Buttons",
  //       count: 6,
  //       route: "/sections/elements/buttons",
  //     },
  //     {
  //       image: `${imagesPrefix}/avatars.jpg`,
  //       name: "Avatars",
  //       count: 2,
  //       route: "/sections/elements/avatars",
  //     },
  //     {
  //       image: `${imagesPrefix}/dropdowns.jpg`,
  //       name: "Dropdowns",
  //       count: 2,
  //       route: "/sections/elements/dropdowns",
  //     },
  //     {
  //       image: `${imagesPrefix}/switch.jpg`,
  //       name: "Toggles",
  //       count: 2,
  //       route: "/sections/elements/toggles",
  //     },
  //     {
  //       image: `${imagesPrefix}/social-buttons.jpg`,
  //       name: "Social Buttons",
  //       count: 2,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/breadcrumbs.jpg`,
  //       name: "Breadcrumbs",
  //       count: 1,
  //       route: "/sections/elements/breadcrumbs",
  //     },
  //     {
  //       image: `${imagesPrefix}/badges.jpg`,
  //       name: "Badges",
  //       count: 3,
  //       route: "/sections/elements/badges",
  //     },
  //     {
  //       image: `${imagesPrefix}/progress.jpg`,
  //       name: "Progress Bars",
  //       count: 4,
  //       route: "/sections/elements/progress-bars",
  //     },
  //     {
  //       image: `${imagesPrefix}/tables.jpg`,
  //       name: "Tables",
  //       count: 3,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/typography.jpg`,
  //       name: "Typography",
  //       count: 2,
  //       route: "/sections/elements/typography",
  //     },
  //   ],
  // },
];
