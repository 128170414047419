/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function BuiltByDevelopers() {
  const bgImage = "";

  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={2}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={11}
          sx={{ ml: { xs: 0, lg: 6 }, justifyContent: "center", alignItems: "center" }}
        >
          <MKTypography
            variant="h2"
            color="white"
            mb={1}
            textAlign="center"
            sx={{
              // Default font size range
              "@media (max-width: 600px)": {
                fontSize: "4vw", // Font size for widths less than or equal to 600px
              },
            }}
          >
            ABOUT
          </MKTypography>
          <MKTypography
            variant="body1"
            color="white"
            opacity={0.8}
            mb={2}
            style={{ textAlign: "justify" }}
            sx={{
              fontSize: "clamp(0.1rem, 10vw, 1.2rem)", // Default font size range
              "@media (max-width: 600px)": {
                fontSize: "4vw", // Font size for widths less than or equal to 600px
              },
            }}
          >
            Welcome to Aprameya, the annual extravaganza orchestrated by the department of MCA at
            Nitte Meenakshi Institute of Technology, Bengaluru! Set to unfold on January 17th and
            18th, 2024, our flagship technical fest is more than an event; it&apos;s an immersive
            experience that transcends boundaries. Embracing the theme, &quot;Innovate, Compete,
            Conquer&quot; we extend a warm invitation for participants to explore the interconnected
            world of technology and unleash your power to conquer challenges. Are you excited to
            showcase your technical prowess?
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BuiltByDevelopers;
