import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function View({ children, title, height, ...rest }) {
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setTimeout(() => setSuccess(false), 3000);
  }, [success]);

  return (
    <MKBox
      width="100%"
      position="relative"
      borderRadius="xl"
      shadow="lg"
      mb={12}
      margin="0"
      paddingBottom="0"
      sx={{ overflow: "hidden" }}
      {...rest}
    >
      <MKBox
        px={3}
        sx={{
          borderBottom: ({ borders: { borderWidth, borderColor } }) =>
            `${borderWidth[1]} solid ${borderColor}`,
        }}
      >
        <Grid container spacing={2} justifyContent="space-between" py={1}>
          <Grid item xs={12} lg={4}>
            <MKTypography
              variant="body1"
              pt={0.5}
              sx={{
                fontSize: { xs: "50%", lg: "inherit" },
                whiteSpace: "pre-wrap", // This allows the text to wrap to the next line
                overflow: "hidden", // Hide any content that overflows the box
              }}
            >
              {title}
            </MKTypography>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox width="100%" p={0}>
        <MKBox
          bgColor="grey-100"
          width="100%"
          height={height}
          maxHeight="40rem"
          borderRadius="0px"
          sx={{
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            zIndex: 99, // This allows the text inside children to wrap to the next line
          }}
        >
          {children}
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

View.defaultProps = {
  height: "auto",
};

View.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
};

export default View;
