import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AppleIcon from "@mui/icons-material/Schedule";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import RulesIcon from "@mui/icons-material/Gavel";
import LogoImage from "assets/images/apralogo.png";
import { Link } from "react-router-dom";

function CustomNavbar() {
  const [menuAnchor, setMenuAnchor] = React.useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: ({ palette: { common } }) => `${common.white}80`,
        boxShadow: "none",
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          backdropFilter: "saturate(200%) blur(30px)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "sticky",
          top: "10px",
          borderRadius: "10px",
          marginLeft: "5%",
          marginTop: "1%",
          marginRight: "5%",
          width: "90%",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)", // Add shadow from all corners
          "@media (max-width: 600px)": {
            marginTop: "3%", // Adjust the font size for mobile responsiveness
          },
        }}
      >
        <Link to="/presentation" style={{ textDecoration: "none", color: "inherit" }}>
          <IconButton color="inherit" edge="start">
            <img src={LogoImage} alt="Logo" style={{ maxWidth: "50px", maxHeight: "50px" }} />
          </IconButton>
        </Link>

        <Button
          color="inherit"
          href="/sections/page-sections/features"
          sx={{
            ml: "auto",
            backgroundColor: "#000",
            color: "#fff",
            position: "relative",
            overflow: "hidden",
            boxShadow: "0 0 10px rgba(255, 0, 0, 0.8)",
            border: "2px solid transparent", // Initial transparent border
            animation: "shadow-pulse 2s infinite, border-pulse 2s infinite", // Add border-pulse animation
            backgroundImage: "var(--gradient-shadow)",
            backgroundSize: "400%",
            "@keyframes shadow-pulse": {
              "0%": {
                boxShadow: "0 0 0px rgba(255, 0, 85, 0.8)", // Gold color
              },
              "50%": {
                boxShadow: "0 0 0px rgba(255, 0, 155, 0.8)", // Fire theme color
              },
              "75%": {
                boxShadow: "0 0 0px rgba(255, 180, 0.8)", // Gold color
              },
              "100%": {
                boxShadow: "0 0 0px rgba(255, 25, 0, 0.8)", // Gold color
              },
            },
            "@keyframes border-pulse": {
              "0%": {
                borderColor: "rgba(255, 155, 10, 5)",
              },
              "50%": {
                borderColor: "rgba(255, 180, 0, 5)",
              },
              "75%": {
                borderColor: "rgba(255, 240, 100, 5)",
              },
              "100%": {
                borderColor: "rgba(255, 175, 40, 5)",
              },
            },
          }}
        >
          EVENTS
        </Button>

        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              backdropFilter: "blur(10px)",
            },
          }}
        >
          <MenuItem
            component={Link}
            to="/sections/elements/progress-bars"
            onClick={handleMenuClose}
          >
            <AppleIcon sx={{ marginRight: 1 }} />
            Schedule
          </MenuItem>
          <MenuItem
            component={Link}
            to="https://firebasestorage.googleapis.com/v0/b/web1-64815.appspot.com/o/APRAMEYA-NMIT-2024.pdf?alt=media&token=a56d0a84-a13f-4904-bdcc-1e2d501edbfe"
            onClick={handleMenuClose}
          >
            <DownloadIcon sx={{ marginRight: 1 }} />
            Brochure
          </MenuItem>
          <MenuItem component={Link} to="/sections/elements/toggles" onClick={handleMenuClose}>
            <RulesIcon sx={{ marginRight: 1 }} />
            General Rules
          </MenuItem>
        </Menu>

        <IconButton color="inherit" edge="end" onClick={handleMenuOpen}>
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default CustomNavbar;
