import React from "react";
import DefaultNavbar from "layouts/sections/elements/typography";
import routes from "routes";
import day1Image from "assets/images/day1.jpg";
import day2Image from "assets/images/day2.jpg";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import { useTheme } from "@mui/material/styles";

function Features() {
  const theme = useTheme();

  const containerStyle = {
    backgroundColor: theme.palette.background.dark,
    padding: "20px",
    paddingTop: "130px",
    minHeight: "100vh",
  };

  const mediaQueryStyle = {
    ...containerStyle,
    "@media (max-width: 600px)": {
      paddingTop: "100px", // Increase the top padding on smaller screens
    },
  };

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "/layouts/pages/presentation",
          label: "HOME",
          color: "dark",
        }}
        sticky
      />

      <div style={mediaQueryStyle}>
        <Grid container spacing={8} justifyContent="center">
          <Grid item>
            <MKBox
              width="fit"
              height="auto"
              overflow="hidden"
              border="2px solid linear-gradient(45deg, #FFD700, #FFA500)"
              boxShadow="0 0 10px rgba(255, 223, 77, 0.5)"
              style={{
                outline: "1px solid #FFD700",
                outlineOffset: "0px",
              }}
            >
              <img src={day1Image} alt="Day 1" style={{ width: "100%", height: "auto" }} />
            </MKBox>
          </Grid>
          <Grid item>
            <MKBox
              width="auto"
              height="fit"
              overflow="hidden"
              border="2px solid linear-gradient(45deg, #FFD700, #FFA500)"
              boxShadow="0 0 10px rgba(255, 223, 77, 0.5)"
              style={{
                outline: "1px solid #FFD700",
                outlineOffset: "0px",
              }}
            >
              <img src={day2Image} alt="Day 2" style={{ width: "100%", height: "auto" }} />
            </MKBox>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Features;
