// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/co-woking.gif";

// ... (other imports)

function HeaderOne() {
  return (
    <MKBox component="header" position="relative" height="100%">
      <MKBox component="nav" position="absolute" top="0.5rem" width="100%">
        <Container>
          <Grid container flexDirection="column" alignItems="left">
            <Stack direction="row" spacing={2}>
              {/* <MKTypography
                component={Link}
                variant="button"
                color="white"
                fontWeight="regular"
                py={0.8125}
                mr={2}
                sx={{
                  "&:hover": {
                    color: "lightgreen", // Highlighted color on hover
                  },
                  "@media (max-width: 600px)": {
                    fontSize: "57%", // Adjust the font size for mobile responsiveness
                  },
                }}
              >
                COORDINATORS:
              </MKTypography>
              <MKTypography
                component={Link}
                href={`https://wa.me/9902605977`}
                target="_blank"
                rel="noreferrer"
                variant="button"
                color="white"
                fontWeight="regular"
                py={0.8125}
                mr={2}
                sx={{
                  "&:hover": {
                    color: "lightgreen", // Highlighted color on hover
                  },
                }}
              >
                {" "}
                Lakshmi Sagar
                <WhatsAppIcon sx={{ ml: 1 }} />
              </MKTypography>
              <MKTypography
                component={Link}
                href={`https://wa.me/9380278724`}
                target="_blank"
                rel="noreferrer"
                variant="button"
                color="white"
                fontWeight="regular"
                py={0.8125}
                mr={2}
                sx={{
                  "&:hover": {
                    color: "lightgreen", // Highlighted color on hover
                  },
                }}
              >
                Akash G K
                <WhatsAppIcon sx={{ ml: 1 }} />
              </MKTypography>
              <MKTypography
                component={Link}
                href={`https://wa.me/8553807938`}
                target="_blank"
                rel="noreferrer"
                variant="button"
                color="white"
                fontWeight="regular"
                py={0.8125}
                mr={2}
                sx={{
                  "&:hover": {
                    color: "lightgreen", // Highlighted color on hover
                  },
                }}
              >
                Athreya
                <WhatsAppIcon sx={{ ml: 1 }} />
              </MKTypography> */}
            </Stack>
          </Grid>
        </Container>
      </MKBox>
      <MKBox
        display="flex"
        alignItems="center"
        minHeight="100%"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} md={7} lg={6} flexDirection="column" justifyContent="center">
            <MKTypography
              variant="h1"
              color="white"
              mb={3}
              sx={({ breakpoints, typography: { size } }) => ({
                fontSize: size["6xl"],
                [breakpoints.down("md")]: {
                  fontSize: size["2xl"],
                },
              })}
            >
              Event Guidelines
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.9}
              pr={9}
              mr={6}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "clamp(0.1rem, 3vw, 1.2rem)", // Default font size range
                "@media (min-width: 300px) and (max-width: 450px)": {
                  fontSize: "2.9vw", // Font size for widths between 768px and 800px
                },
                "@media (min-width: 451px) and (max-width: 562px)": {
                  fontSize: "3vw", // Font size for 700px and below
                },
                "@media (min-width: 575px) and (max-width: 618px)": {
                  fontSize: "2.5vw", // Font size for widths between 768px and 800px
                },
                "@media (min-width: 619px) and (max-width: 767px)": {
                  fontSize: "2.2vw", // Font size for widths between 768px and 800px
                },
              }}
            >
              🔥 Participants must carry their college ID card with them.
              <br />
              🔥 On-spot Registration is not allowed.
              <br />
              🔥 Registration fees are not refundable.
              <br />
              🔥 Misbehaviour or indiscipline will lead to disqalification.
              <br />
              🔥 Use of state-altering substances is strictly prohibited.
              <br />
              🔥 Participants are requested to bring their own gadgets.
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No gadget will be provided by the organizing team{" "}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; at the day of the event.
              <br />
              {/* 🔥 Instruction of each event will be given by the respective
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;coordinators before the event. <br /> */}
              🔥 As all the events are conducted in campus, participants
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;presence in the campus is must.
              <br />
              🔥 Judges decision will be final.
              <br />
              🔥 College will not be responsible for any loss of valuables.
              <br />
              🔥 Students must register to events according to event <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;schedule and no student is allowed to register in
              multiple
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;simultaneous scheduled events.
              <br />
              🔥 The organizing team will not be responsible if the
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;participant is unable to follow the time schedule.
              <br />
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default HeaderOne;
