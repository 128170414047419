// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import React from "react";

import View from "layouts/sections/components/View";

// PageHeaders page components
// import HeaderOne from "layouts/sections/elements/avatars/components/AvatarGroup";
import HeaderTwo from "layouts/sections/elements/avatars/components/AvatarSize";

// PageHeaders page components code

// import View from "layouts/sections/components/View";

// PageHeaders page components
// import HeaderOne from "layouts/sections/navigation/navbars/components/NavbarDark";

// PageHeaders page components code

function PageHeaders() {
  return (
    <BaseLayout
      title="Rules"
      breadcrumb={[
        { label: "Home", route: "/presentation" },
        { label: "Events", route: "/sections/page-sections/features" },
        { label: "Gaming" },
      ]}
    >
      {/* <View title="Event Schedule - Day 1(10:45am-4:00am)" height="50rem">
        <HeaderOne />
      </View> */}
      <View title="Event Schedule - Day 2(9:30am-1:00pm)" height="50rem">
        <HeaderTwo />
      </View>
    </BaseLayout>
  );
}

export default PageHeaders;
