import React, { Component } from "react";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
// import Counters from './Counters'; // Assuming Counters is in the same directory

class Example extends Component {
  render() {
    const containerStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // height: "100vh", // Optional: Set a height for the container to center vertically
    };

    return (
      <div style={containerStyle}>
        <FlipClockCountdown id="myFlipClock" to={new Date("January 25, 2024 18:00:00").getTime()} />
        {/* <Counters /> */}
      </div>
    );
  }
}

// Combined CSS and JS
const styles = `
  /* Default styles for larger screens */
  #myFlipClock {
    --fcc-digit-block-width: 7vw; /* Adjust the width for smaller screens */
    --fcc-digit-block-height: 12vw; /* Adjust the height for smaller screens */
    --fcc-digit-font-size: 6vw;
    --fcc-label-color: #808080;
    --fcc-background: #5A5A5A;
  }

  /* Media query for smaller screens (e.g., mobile devices) */
  @media only screen and (max-width: 767px) {
    #myFlipClock {
      --fcc-digit-block-width: 7vw; /* Adjust the width for smaller screens */
      --fcc-digit-block-height: 12vw; /* Adjust the height for smaller screens */
      --fcc-digit-font-size: 6vw; /* Adjust the font size for smaller screens */
      --fcc-label-color: #808080;
      --fcc-background: #5A5A5A;
    }
  }
`;

class ExampleWithStyles extends Component {
  render() {
    return (
      <>
        <style>{styles}</style>
        <Example />
      </>
    );
  }
}

export default ExampleWithStyles;
