// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import React from "react";

import View from "layouts/sections/components/View";

// PageHeaders page components
import HeaderOne from "layouts/sections/elements/toggles/components/Toggle";

// PageHeaders page components code

// import View from "layouts/sections/components/View";

// PageHeaders page components
// import HeaderOne from "layouts/sections/navigation/navbars/components/NavbarDark";

// PageHeaders page components code

function PageHeaders() {
  return (
    <BaseLayout
      title="Rules"
      breadcrumb={[
        { label: "Home", route: "/presentation" },
        { label: "Events", route: "/sections/page-sections/features" },
        { label: "General Rules" },
      ]}
    >
      <View title="" height="50rem">
        <HeaderOne />
      </View>
      {/* <View title="Event Schedule - Day 1(2:00pm-4:00pm)" height="50rem">
        <HeaderOne />
      </View> */}
    </BaseLayout>
  );
}

export default PageHeaders;
