/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import React from "react";

import View from "layouts/sections/components/View";

// PageHeaders page components
import HeaderOne from "layouts/sections/navigation/navbars/components/NavbarDark";

// PageHeaders page components code

// import View from "layouts/sections/components/View";

// PageHeaders page components
// import HeaderOne from "layouts/sections/navigation/navbars/components/NavbarDark";

// PageHeaders page components code

function PageHeaders() {
  return (
    <BaseLayout
      title="Rules"
      breadcrumb={[
        { label: "Home", route: "/presentation" },
        { label: "Events", route: "/sections/page-sections/features" },
        { label: "BINARY BRAINS" },
      ]}
    >
      <View title="Event Schedule - Day 1(10:30pm-1:00pm) Day 2(9:30pm-10:45pm)" height="30rem">
        <HeaderOne />
      </View>
    </BaseLayout>
  );
}

export default PageHeaders;
