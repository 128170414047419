import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
    <div style={{ backgroundColor: "black", margin: 0, padding: 0 }}>
      <App />
    </div>
  </BrowserRouter>
);
