import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DefaultFooter({ content }) {
  const { brand, socials, copyright } = content;

  return (
    <MKBox component="footer" textAlign="center" py={4}>
      <Container>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={3}>
            <MKBox display="flex" flexDirection="column" alignItems="center">
              <Link to={brand.route}>
                <MKBox
                  component="img"
                  src={brand.image}
                  target="_blank"
                  alt={brand.name}
                  maxHeight={150} // Set your desired max height
                  maxWidth="90vw" // Adjust the width as needed
                  mb={2}
                  style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
                />
              </Link>
              <MKTypography variant="h6">{brand.name}</MKTypography>
              <MKBox display="flex" alignItems="" mt={3}>
                {socials.map(({ icon, link }, key) => (
                  <MKTypography
                    key={link}
                    component="a"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    variant="h5"
                    color="light"
                    opacity={0.8}
                    mr={key === socials.length - 1 ? 0 : 2.5}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {icon}
                  </MKTypography>
                ))}
              </MKBox>
            </MKBox>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center", mt: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
