import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import DefaultNavbar from "layouts/sections/elements/typography";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Counters from "pages/Presentation/sections/Counters";
import MKTypography from "components/MKTypography";
// import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";
import routes from "routes";
import footerRoutes from "footer.routes";
import bgImage from "assets/images/bg-presentation.gif";
import stackedImage from "assets/images/logo.png";
import hod from "assets/images/hod.jpg";
import laxmi from "assets/images/laxmi.jpeg";
import aterya from "assets/images/atreya.jpg";
import akash from "assets/images/akash.jpg";
import jason from "assets/images/Jason.jpg";
import Vikas from "assets/images/vikas.jpg";
import Loka from "assets/images/loka.jpg";
import Ruchi from "assets/images/ruchi.jpg";
import Nandini from "assets/images/nadini.jpg";
import Bhupal from "assets/images/bhopal.jpg";
import Pavan from "assets/images/pavan.jpg";

// import appu from "assets/images/appu.jpeg";
import joy from "assets/images/joy.png";
// Replace with your stacked image URL
// import events from "/Users/jasonrodrigues/Downloads/material-kit-react-main-2/src/events.js";

function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "/sections/page-sections/features", //browcher download
          label: "EVENTS",
          color: "dark",
        }}
        sticky
      />
      <MKBox minHeight="75vh" width="100%" sx={{ position: "relative" }}>
        <div
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            minHeight: "75vh",
            width: "100%",
            position: "absolute",
          }}
        />
        <img
          src={stackedImage}
          alt="Stacked Image"
          style={{
            maxWidth: "100%",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKBox mt={-6} mb={1}>
              {"\u00A0"}
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(20px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        {/* <DesignBlocks /> */}
        <BuiltByDevelopers />
      </Card>
      {/* "Fest Coordinators" Section */}
      <MKBox py={0} textAlign="center">
        <MKTypography variant="h4" fontWeight="bold" color="light">
          Coordinators
        </MKTypography>
        <Grid container spacing={8} justifyContent="center" mt={-2}>
          {/* hod */}
          <Grid item>
            <MKBox
              width="135px"
              height="135px"
              borderRadius="50%"
              overflow="hidden"
              border="4px solid linear-gradient(45deg, #FFD700, #FFA500)"
              boxShadow="0 0 10px rgba(255, 223, 77, 0.5)"
              style={{
                outline: "4px solid #FFD700", // White border
                outlineOffset: "0px", // Adjust to control the width of the white border
              }}
            >
              <img
                src={hod}
                alt="Stacked Image"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "50px solid linear-gradient(45deg, #FFD700, #FFA500)",
                  boxShadow: "0 0 10px rgba(255, 223, 77, 0.5)",
                }}
              />
            </MKBox>
            <MKTypography variant="body2" color="white" mt={1} textAlign="center">
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>Dr.Shreekanth R</span>
              <br />
              <span style={{ fontSize: "14px" }}>HOD MCA</span>
            </MKTypography>
          </Grid>
          {/* joy */}
          <Grid item>
            <MKBox
              width="135px"
              height="135px"
              borderRadius="50%"
              overflow="hidden"
              border="4px solid linear-gradient(45deg, #FFD700, #FFA500)"
              boxShadow="0 0 10px rgba(255, 223, 77, 0.5)"
              style={{
                outline: "4px solid #FFD700", // White border
                outlineOffset: "0px", // Adjust to control the width of the white border
              }}
            >
              <img
                src={joy}
                alt="Stacked Image"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "50px solid linear-gradient(45deg, #FFD700, #FFA500)",
                  boxShadow: "0 0 10px rgba(255, 223, 77, 0.5)",
                }}
              />
            </MKBox>
            <MKTypography variant="body2" color="white" mt={1} textAlign="center">
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>Ms.Joy Lavinya</span>
              <br />
              <span style={{ fontSize: "14px" }}>Event Coordinator</span>
            </MKTypography>
          </Grid>

          {/* laxmi*/}
          <Grid item>
            <MKBox
              width="135px"
              height="135px"
              borderRadius="50%"
              overflow="hidden"
              border="4px solid linear-gradient(45deg, #FFD700, #FFA500)"
              boxShadow="0 0 10px rgba(255, 223, 77, 0.5)"
              style={{
                outline: "4px solid #FFD700", // White border
                outlineOffset: "0px", // Adjust to control the width of the white border
              }}
            >
              <img
                src={laxmi}
                alt="Stacked Image"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "50px solid linear-gradient(45deg, #FFD700, #FFA500)",
                  boxShadow: "0 0 10px rgba(255, 223, 77, 0.5)",
                }}
              />
            </MKBox>
            <MKTypography variant="body2" color="white" mt={1} textAlign="center">
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>Lakshmisagar R</span>
              <br />
              <span style={{ fontSize: "14px" }}>Sparks President</span>
            </MKTypography>
          </Grid>
          {/* aterya*/}
          <Grid item>
            <MKBox
              width="135px"
              height="135px"
              borderRadius="50%"
              overflow="hidden"
              border="4px solid linear-gradient(45deg, #FFD700, #FFA500)"
              boxShadow="0 0 10px rgba(255, 223, 77, 0.5)"
              style={{
                outline: "4px solid #FFD700", // White border
                outlineOffset: "0px", // Adjust to control the width of the white border
              }}
            >
              <img
                src={aterya}
                alt="Stacked Image"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "50px solid linear-gradient(45deg, #FFD700, #FFA500)",
                  boxShadow: "0 0 10px rgba(255, 223, 77, 0.5)",
                }}
              />
            </MKBox>
            <MKTypography variant="body2" color="white" mt={1} textAlign="center">
              <span style={{ fontWeight: "bold", fontSize: "15px" }}>Athreya BN</span>
              <br />
              <span style={{ fontSize: "14px" }}>Technical Secretary</span>
            </MKTypography>
          </Grid>

          {/* akash */}
          <Grid item>
            <MKBox
              width="135px"
              height="135px"
              borderRadius="50%"
              overflow="hidden"
              border="4px solid linear-gradient(45deg, #FFD700, #FFA500)"
              boxShadow="0 0 10px rgba(255, 223, 77, 0.5)"
              style={{
                outline: "4px solid #FFD700", // White border
                outlineOffset: "0px", // Adjust to control the width of the white border
              }}
            >
              <img
                src={akash}
                alt="Stacked Image"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "50px solid linear-gradient(45deg, #FFD700, #FFA500)",
                  boxShadow: "0 0 10px rgba(255, 223, 77, 0.5)",
                }}
              />
            </MKBox>
            <MKTypography variant="body2" color="white" mt={1} textAlign="center">
              <span style={{ fontWeight: "bold", fontSize: "15px" }}>Akash GK</span>
              <br />
              <span style={{ fontSize: "14px" }}>Asst.Technical Secretary</span>
            </MKTypography>
          </Grid>
          {/* jason */}
          <Grid item>
            <MKBox
              width="135px"
              height="135px"
              borderRadius="50%"
              overflow="hidden"
              border="4px solid linear-gradient(45deg, #FFD700, #FFA500)"
              boxShadow="0 0 10px rgba(255, 223, 77, 0.5)"
              style={{
                outline: "4px solid #FFD700", // White border
                outlineOffset: "0px", // Adjust to control the width of the white border
              }}
            >
              <img
                src={jason}
                alt="Stacked Image"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "50px solid linear-gradient(45deg, #FFD700, #FFA500)",
                  boxShadow: "0 0 10px rgba(255, 223, 77, 0.5)",
                }}
              />
            </MKBox>
            <MKTypography variant="body2" color="white" mt={1} textAlign="center">
              <span style={{ fontWeight: "bold", fontSize: "15px" }}>Jason R</span>
              <br />
              <span style={{ fontSize: "13.5px" }}>Graphic & Web Designer</span>
            </MKTypography>
          </Grid>
        </Grid>
      </MKBox>
      <br />
      <br />
      {/* Almuni */}
      <MKBox py={0} textAlign="center">
        <MKTypography variant="h4" fontWeight="bold" color="light">
          Alumni Sponsers
        </MKTypography>
        <Grid container spacing={8} justifyContent="center" mt={-2}>
          {/* Pavan */}
          <Grid item>
            <MKBox
              width="135px"
              height="135px"
              borderRadius="50%"
              overflow="hidden"
              border="4px solid linear-gradient(45deg, #FFD700, #FFA500)"
              boxShadow="0 0 10px rgba(255, 223, 77, 0.5)"
              style={{
                outline: "4px solid #FFD700", // White border
                outlineOffset: "0px", // Adjust to control the width of the white border
              }}
            >
              <img
                src={Pavan}
                alt="Stacked Image"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "50px solid linear-gradient(45deg, #FFD700, #FFA500)",
                  boxShadow: "0 0 10px rgba(255, 223, 77, 0.5)",
                }}
              />
            </MKBox>
            <MKTypography variant="body2" color="white" mt={1} textAlign="center">
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>Mr.Pavan Kumar</span>
              <br />
              <span style={{ fontSize: "14px" }}>
                Senior Associate projects
                <br />
                COGNIZANT
              </span>
            </MKTypography>
          </Grid>
          {/* Bhupal */}
          <Grid item>
            <MKBox
              width="135px"
              height="135px"
              borderRadius="50%"
              overflow="hidden"
              border="4px solid linear-gradient(45deg, #FFD700, #FFA500)"
              boxShadow="0 0 10px rgba(255, 223, 77, 0.5)"
              style={{
                outline: "4px solid #FFD700", // White border
                outlineOffset: "0px", // Adjust to control the width of the white border
              }}
            >
              <img
                src={Bhupal}
                alt="Stacked Image"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "50px solid linear-gradient(45deg, #FFD700, #FFA500)",
                  boxShadow: "0 0 10px rgba(255, 223, 77, 0.5)",
                }}
              />
            </MKBox>
            <MKTypography variant="body2" color="white" mt={1} textAlign="center">
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>Mr.Bhupal Singh</span>
              <br />
              <span style={{ fontSize: "14px" }}>
                Technical Manager <br />
                at EVERTZ
              </span>
            </MKTypography>
          </Grid>

          {/* nandini*/}
          <Grid item>
            <MKBox
              width="135px"
              height="135px"
              borderRadius="50%"
              overflow="hidden"
              border="4px solid linear-gradient(45deg, #FFD700, #FFA500)"
              boxShadow="0 0 10px rgba(255, 223, 77, 0.5)"
              style={{
                outline: "4px solid #FFD700", // White border
                outlineOffset: "0px", // Adjust to control the width of the white border
              }}
            >
              <img
                src={Nandini}
                alt="Stacked Image"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "50px solid linear-gradient(45deg, #FFD700, #FFA500)",
                  boxShadow: "0 0 10px rgba(255, 223, 77, 0.5)",
                }}
              />
            </MKBox>
            <MKTypography variant="body2" color="white" mt={1} textAlign="center">
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>Ms.Nandini</span>
              <br />
              <span style={{ fontSize: "14px" }}>
                Assistant Consultant <br />
                at TCS
              </span>
            </MKTypography>
          </Grid>
          {/* Ruchi*/}
          <Grid item>
            <MKBox
              width="135px"
              height="135px"
              borderRadius="50%"
              overflow="hidden"
              border="4px solid linear-gradient(45deg, #FFD700, #FFA500)"
              boxShadow="0 0 10px rgba(255, 223, 77, 0.5)"
              style={{
                outline: "4px solid #FFD700", // White border
                outlineOffset: "0px", // Adjust to control the width of the white border
              }}
            >
              <img
                src={Ruchi}
                alt="Stacked Image"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "50px solid linear-gradient(45deg, #FFD700, #FFA500)",
                  boxShadow: "0 0 10px rgba(255, 223, 77, 0.5)",
                }}
              />
            </MKBox>
            <MKTypography variant="body2" color="white" mt={1} textAlign="center">
              <span style={{ fontWeight: "bold", fontSize: "15px" }}>Ms.Ruchitha Joshi</span>
              <br />
              <span style={{ fontSize: "14px" }}>
                Senior Program Manager <br />
                at Great Learning
              </span>
            </MKTypography>
          </Grid>

          {/* Loka */}
          <Grid item>
            <MKBox
              width="135px"
              height="135px"
              borderRadius="50%"
              overflow="hidden"
              border="4px solid linear-gradient(45deg, #FFD700, #FFA500)"
              boxShadow="0 0 10px rgba(255, 223, 77, 0.5)"
              style={{
                outline: "4px solid #FFD700", // White border
                outlineOffset: "0px", // Adjust to control the width of the white border
              }}
            >
              <img
                src={Loka}
                alt="Stacked Image"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "50px solid linear-gradient(45deg, #FFD700, #FFA500)",
                  boxShadow: "0 0 10px rgba(255, 223, 77, 0.5)",
                }}
              />
            </MKBox>
            <MKTypography variant="body2" color="white" mt={1} textAlign="center">
              <span style={{ fontWeight: "bold", fontSize: "15px" }}>Mr.Lokaatthan</span>
              <br />
              <span style={{ fontSize: "14px" }}>
                Software Developer <br />
                at SAP LABS INDIA
              </span>
            </MKTypography>
          </Grid>
          {/* vikas */}
          <Grid item>
            <MKBox
              width="135px"
              height="135px"
              borderRadius="50%"
              overflow="hidden"
              border="4px solid linear-gradient(45deg, #FFD700, #FFA500)"
              boxShadow="0 0 10px rgba(255, 223, 77, 0.5)"
              style={{
                outline: "4px solid #FFD700", // White border
                outlineOffset: "0px", // Adjust to control the width of the white border
              }}
            >
              <img
                src={Vikas}
                alt="Stacked Image"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "50px solid linear-gradient(45deg, #FFD700, #FFA500)",
                  boxShadow: "0 0 10px rgba(255, 223, 77, 0.5)",
                }}
              />
            </MKBox>
            <MKTypography variant="body2" color="white" mt={1} textAlign="center">
              <span style={{ fontWeight: "bold", fontSize: "15px" }}>Mr.Vikas</span>
              <br />
              <span style={{ fontSize: "14px" }}>
                Senior Consultant <br />
                at CAPGEMINI
              </span>
            </MKTypography>
          </Grid>
        </Grid>
      </MKBox>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
